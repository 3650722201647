import React, { useState, useContext, useEffect } from 'react';
import Sidebar from '../../Sidebar';
import Header from '../../Header';
import { IdiomContext } from "../../../../context/createcontext"
import { useTranslation } from 'react-i18next';
import ClientForm from './ClientForm';
import { useParams } from 'react-router-dom';


export default function ClientsFormPage() {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [lng, updateLng] = useContext(IdiomContext);
    const [t, i18n] = useTranslation("global");
    const pathParams = useParams();
    let idClient = pathParams.id;

    return (
        <div className={`sec-neutro-background flex h-screen overflow-hidden`}>

            {/* Sidebar */}
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

            {/* Content area */}
            <div className="sec-neutro-background relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

                {/*  Site header */}
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

                <main>
                    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                            {/* Title */}
                            <button className="ml-4 mb-2 text-base font-semibold flex flex-row items-center" type='onclick' onClick={() => window.history.back()}  >
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-back" width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#334155" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <path d="M9 11l-4 4l4 4m-4 -4h11a4 4 0 0 0 0 -8h-1" />
                                </svg>
                                <span>{t("footPrint.volver")}</span>
                            </button>
                            <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">{(idClient != undefined ? t("clients.editar") : t("clients.nuevo")) + " " + t("clients.Cliente")}</h1>
                            <ClientForm />
                    </div>
                </main>
            </div>
        </div>

    )
}
