import axios from "axios";
import { useState } from "react";
import { useTranslation } from 'react-i18next';
import UserService from "../../../services/UserService";
import { Modal } from "react-bootstrap";
import { Spinner } from "react-bootstrap";
import toast, { Toaster } from 'react-hot-toast';


export default function ModalLogisticClientReport({ modal, setModal, clientList }) {
    const [t, i18n] = useTranslation("global");
    const [gettingReport, setGettingReport] = useState(false);

    const [selectAll, setSelectAll] = useState(false);
    const [selectedClients, setSelectedClients] = useState([]);

    const handleSelectAllChange = (event) => {
        const isChecked = event.target.checked;
        setSelectAll(isChecked);

        if (isChecked) {
            const allClientIds = clientList.map(client => client.id);
            setSelectedClients(allClientIds);
        } else {
            setSelectedClients([]);
        }
    };

    const handleClientSelect = (clientId) => {
        setSelectedClients((prevSelected) => {
            if (prevSelected.includes(clientId)) {
                return prevSelected.filter(id => id !== clientId);
            } else {
                return [...prevSelected, clientId];
            }
        });
    };

    // Verifica si todos los clientes están seleccionados
    const allSelected = clientList?.length === selectedClients.length;


    const handleCloseReport = () => {
        setModal({ view: false, id: null });
        setSelectedClients([]);
    }


    const handleCreateClientReport = async (e) => {
        e.preventDefault();
        setGettingReport(true)

        toast.promise(
            axios
                .get(`${process.env.REACT_APP_CH_CALCULATION}/${modal.id}/report/excel/logistic?clientsList=${selectedClients ? selectedClients.map(client => client) : ''}`, {
                    responseType: 'blob',
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${UserService.getToken()}`,
                    },
                }).then((response) => {
                    if (response.status === 200) {
                        const blob = response.data; // El archivo binario (ZIP)
                        const url = window.URL.createObjectURL(blob);
                        const dateStr = new Date().toISOString().slice(0, 10).replace(/-/g, ''); // yyyyMMdd
                        const fileName = `logistic_report_${dateStr}.zip`;

                        // Crear un enlace de descarga
                        const link = document.createElement('a');
                        link.href = url;
                        link.download = fileName;
                        link.click();

                        // Limpiar el objeto URL después de la descarga
                        window.URL.revokeObjectURL(url);
                        return response.data;
                    } else {
                        console.error('Error al obtener el archivo ZIP:', error);

                        throw new Error(response.data.message || 'Error inesperado');
                    }
                }),
            {
                loading: t("footPrint.report.downloading"),
                success: <b>{t("footPrint.report.downloaded")}</b>,
                error: <b>{t("footPrint.report.error")}</b>,
            }
        ).finally(
            () => setGettingReport(false)
        );

    }

    return (<Modal show={modal.view} animation={false} data-aos="fade-up" >
        <Toaster
            position="bottom-right"
            reverseOrder={false}
            toastOptions={{
                duration: 8000
            }}
        />
        <Modal.Header className='sec-green-background'>
            <Modal.Title>{t("footPrint.report.title")} {modal.id}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="flex justify-center">

                <table className="simple-table">
                    <thead>
                        <tr>
                            <th><input
                                type="checkbox"
                                checked={allSelected}
                                onChange={handleSelectAllChange} ></input>
                            </th>
                            <th>{t("footPrint.report.client")}</th>
                        </tr>
                    </thead>
                    <tbody>

                        {clientList && <>
                            {clientList.map(client => (<tr key={client.id}>
                                <td><input
                                    type="checkbox"
                                    checked={selectedClients.includes(client.id)}
                                    onChange={() => handleClientSelect(client.id)} ></input>
                                </td>
                                <td>{client.name}</td>
                            </tr>))}
                        </>
                        }
                    </tbody>
                </table>
            </div>
            <div className='flex space-x-4 justify-center mt-4'>
                {selectedClients && selectedClients.length > 0 &&
                    <button onClick={(e) => handleCreateClientReport(e)}
                        disabled={gettingReport}
                        className='btn-sm btn-green text-xs  p-2'>
                        {gettingReport ?
                            <Spinner
                                animation="border"
                                variant="light"
                            /> :
                            t("footPrint.report.get")
                        }
                    </button>}
                {!selectedClients || selectedClients.length === 0 && <span className="text-xs">{t("footPrint.report.selectClient")}</span>}
            </div>
        </Modal.Body>
        <Modal.Footer>
            <button onClick={() => handleCloseReport()} className='btn-sm btn-cancel text-xs'>{t("footPrint.cerrarCerrar")}</button>
        </Modal.Footer>
    </Modal >)
}