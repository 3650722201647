import React from "react";
import { useState } from "react";
import { useTranslation } from 'react-i18next';
import gpTableData from '../../intranet/data/gp_datos_tabla.json';
import { formatThousands } from "../../intranet/utils/Utils";


export default function responsiveTable() {
    const [t, i18n] = useTranslation("global");
    const cert = process.env.REACT_APP_URL + '/pdf/applus_certificado_gp.pdf';

    const certBAM = process.env.REACT_APP_URL + "/VerrayBAM_Cencosud.pdf";

    const [tableData, setTableData] = useState(gpTableData);
    return (<div className="table-responsive rounded-lg my-3">
        <table className="table bg-white text-xs table-auto w-full">
            <tbody className="text-xs text-gp-lightgrey">
                <tr>
                    <td className="font-bold">{t("gp.block4.table.th1")}</td>
                    {tableData.map((row, index) => (
                        <td className="text-center" key={index + "-lote"}>{row.Lote}</td>))
                    }
                </tr>
                <tr>
                    <td className="font-bold">{t("gp.block4.table.th2")}</td>
                    {tableData.map((row, index) => (
                        <td className="text-center" key={index + "-fecha"}>{row.Fecha}</td>))
                    }
                </tr>
                <tr>
                    <td className="font-bold">SKU</td>
                    {tableData.map((row, index) => (
                        <td className="text-center" key={index + "-SKU"}>{row.SKU}</td>))
                    }
                </tr>
                <tr>
                    <td className="font-bold">{t("gp.block4.table.th3")}</td>
                    {tableData.map((row, index) => (
                        <td className="text-center" key={index + "-cantidad"}>{row.Cantidad}</td>))
                    }
                </tr>
                <tr>
                    <td className="font-bold">{t("gp.block4.table.th8")}</td>
                    {tableData.map((row, index) => (
                        <td className="text-center" key={index + "-toneladasEmitidas"}>{formatThousands(row.ToneladasEmitidas)}</td>))
                    }
                </tr>
                <tr>
                    <td className="font-bold">{t("gp.block4.table.th4")}</td>
                    {tableData.map((row, index) => (
                        <td className="text-center" key={index + "-toneladasCompensadas"}>{formatThousands(row.ToneladasCompensadas)}</td>))
                    }
                </tr>
                <tr>
                    <td className="font-bold">{t("gp.block4.table.th5")}</td>
                    {tableData.map((row, index) => (
                        <td className="text-center" key={index + "-enlace"}>
                            <a
                                href={certBAM}
                                target="_blank"
                                onClick={(e) => {
                                    e.preventDefault();
                                    window.open(certBAM, "_blank");
                                }}
                            >
                                BAM Madre de Dios Amazon REDD+ Project
                            </a>
                        </td>))
                    }
                </tr>
                <tr>
                    <td className="font-bold">{t("gp.block4.table.th7")}</td>
                    {tableData.map((row, index) => (
                        <td className="text-center" key={index + "-IDProyecto"}>{formatThousands(row.IDProyecto)}</td>))
                    }
                </tr>
                <tr>
                    <td className="font-bold">{t("gp.block4.table.th6")}</td>
                    {tableData.map((row, index) => (
                        <td className="text-center" key={index + "-verra"}>
                            <a href="https://registry.verra.org/app/projectDetail/VCS/868" target="_blank">
                                {t("gp.block4.table.tdBosques")}
                            </a>
                        </td>))
                    }

                </tr>
                <tr className="border-t" />

            </tbody>
        </table>
    </div>
    )
}