import { useTranslation } from 'react-i18next';
import { formatDate, formatThousands } from '../../../utils/Utils';
import ReactTooltip from 'react-tooltip';
import { useEffect } from 'react';

export default function SaveAnswerResponse({ savedAnswer, showAnswerTableByQuestion, initCurrentQuestion, finishQuestionnaireFromSavedAnswers }) {
    const [t, i18n] = useTranslation("global");

    useEffect(() => {
        const buttonElement = document.getElementById('button-with-tooltip');
        const finishButtonElement = document.getElementById('finish-button-with-tooltip');

        if (buttonElement) {
            buttonElement.dispatchEvent(new MouseEvent('mouseenter', { bubbles: true }));
        }
        if (finishButtonElement) {
            finishButtonElement.dispatchEvent(new MouseEvent('mouseenter', { bubbles: true }));
        }
    }, [savedAnswer]);

    return <div>
        {savedAnswer.success &&
            <>
                <div className='text-lg font-bold'>{t("fpThird.addAnswerOk1")}</div>
                <div><span>{t("fpThird.addAnswerOk2")}</span><span className='airco2-a-tag' onClick={() => showAnswerTableByQuestion(true)} >{t("fpThird.addAnswerOk3")}</span></div>

                {savedAnswer.data.map(tables => (<div className="container-simple-table mt-2 mb-4">
                    <table className="simple-table">
                        <thead>
                            <tr>
                                {Object.keys(tables.answerHeaderOutputDto).map((key) => (
                                    <th key={key} className="px-4 py-2" style={{ overflowWrap: 'break-word' }}>
                                        {tables.answerHeaderOutputDto[key]}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {tables.answerBodyOutputDtos.length > 0 &&
                                tables.answerBodyOutputDtos.map(item =>
                                    <tr >
                                        {Object.keys(tables.answerHeaderOutputDto).map((key) => (
                                            <td key={key} className="px-4 py-2" >
                                                {item[key] === undefined || item[key] === "" ?
                                                    "" : ((isNaN(item[key])
                                                        ? (key === "registrationDate"
                                                            ? formatDate(item[key])
                                                            : item[key])
                                                        : formatThousands(item[key])))}
                                            </td>
                                        ))}

                                    </tr>

                                )}
                        </tbody>
                    </table>
                </div>))}
            </>
        }
        {
            !savedAnswer.success && <>
                <div className='text-lg font-bold'>{t("fpThird.addAnswerKO1")}</div>
                <div>{t("fpThird.addAnswerKO2")}</div>
            </>
        }
        <div className={`flex items-center justify-center md:justify-between gap-4 flex-wrap`} style={{ marginTop: '3rem' }}>
            <button id="button-with-tooltip" data-tip data-for="tooltip" className="btn-sm btn-cancel text-md  rounded-lg px-8 py-2" type="submit"
                onClick={() => initCurrentQuestion(savedAnswer.data[0].answerBodyOutputDtos[0])}>
                {t("fpThird.addOtherAnswer")}
            </button>
            <ReactTooltip id="tooltip" place="top" effect="solid" getContent={() => "Si quieres añadir otro viaje distinto, haz click en este botón"} />

            <button id="finish-button-with-tooltip" data-tip data-for="tooltip-finish" className="btn-sm btn-green text-md font-semibold rounded-lg px-8 py-2 gap-2" type="button"
                onClick={() => finishQuestionnaireFromSavedAnswers(true)}>
                <span>{t("fpThird.finalizar")}</span>
                <svg xmlns="http://www.w3.org/2000/svg"   viewBox="0 0 24 24" fill="none" stroke="currentColor"  strokeLinecap="round" strokeLinejoin="round" width={24} height={24}  strokeWidth={2}> <path d="M8.56 3.69a9 9 0 0 0 -2.92 1.95"></path> <path d="M3.69 8.56a9 9 0 0 0 -.69 3.44"></path> <path d="M3.69 15.44a9 9 0 0 0 1.95 2.92"></path> <path d="M8.56 20.31a9 9 0 0 0 3.44 .69"></path> <path d="M15.44 20.31a9 9 0 0 0 2.92 -1.95"></path> <path d="M20.31 15.44a9 9 0 0 0 .69 -3.44"></path> <path d="M20.31 8.56a9 9 0 0 0 -1.95 -2.92"></path> <path d="M15.44 3.69a9 9 0 0 0 -3.44 -.69"></path> <path d="M9 12l2 2l4 -4"></path> </svg> 
            </button>
            <ReactTooltip id="tooltip-finish" place="top" effect="solid" getContent={() => "Si tu información es correcta, finaliza el cuestionario"} />

        </div>

    </div >
}