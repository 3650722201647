import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';
import toast, { Toaster } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import Select from "react-select";
import UserService from '../../../../services/UserService';
export default function VehicleFleetForm({ id, openInModal, handleOpenEditNewWindow, setNewCreatedModalElement }) {
    const [t, i18n] = useTranslation("global");
    const navigate = useNavigate();
    const pathParams = useParams();
    let idVehicleFleet = pathParams?.id;
    if (id) {
        idVehicleFleet = id;
    }

    const initialFormData = {
        id: '',
        identifier: '',
        license: '',
        isOwned: false,
        averageConsumption: '',
        emptyConsumption: '',
        payload: '',
        mma: '',
        isRefrigerated: false,
        isActive: false,
        additionalConsumption: '',
        refrigerationType: undefined,
        refrigerationUnitType: undefined,
        organizationId: undefined,
        vehiclesMachineryId: undefined,
        combustibleId: undefined,
        userId: UserService.getUserId(),
        newVehicle: false
    };

    const initialSelectedVehicleCombustible = {
        transportType: undefined,
        vehicleType: undefined,
        combustible: undefined
    };

    const [isFetching, setIsFetching] = useState(false);
    const [formData, setFormData] = useState(initialFormData);

    const [showSpinner, setShowSpinner] = useState(false);
    const [organizations, setOrganizations] = useState({
        data: [],
        isFetching: true
    });
    const [refrigerationType, setRefrigerationType] = useState({
        data: [],
        isFetching: true
    });
    const [refrigerationUnitType, setRefrigerationUnitType] = useState({
        data: [],
        isFetching: true
    });
    const [vehiclesCombustible, setVehiclesCombustible] = useState({
        data: [],
        isFetching: true
    });
    const [selectedVehicleCombustible, setSelectedVehicleCombustible] = useState(initialSelectedVehicleCombustible);
    const [requiredConsumption, setRequiredConsumption] = useState(false);

    useEffect(() => {
        var fetchVehicle = async () => {
            try {
                setIsFetching(true);
                const response = await axios.get(`${process.env.REACT_APP_CH_VEHICLE_FLEET}/${idVehicleFleet}`, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${UserService.getToken()}`
                    }
                });
                setFormData(response.data.data);

            } catch (exception) {
                console.error('Error fetching vehicle fleet data:', error);
            } finally {
                setIsFetching(false);
            }
        };
        if (idVehicleFleet !== undefined) {
            fetchVehicle();
        }
    }, []);

    useEffect(() => {
        const fetchOrganizations = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_CH_ORGANIZATIONS}?userId=${UserService.getUserId()}`, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${UserService.getToken()}`
                    }
                });
                setOrganizations({ data: response.data, isFetching: false });
            } catch (exception) {
                setOrganizations({ data: [], isFetching: false });
                console.error(exception.message)
            }
        };

        const fetchRefrigerationType = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_CH_REFRIGERATION_TYPE}`, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${UserService.getToken()}`
                    }
                });
                setRefrigerationType({ data: response.data.data, isFetching: false });
            } catch (exception) {
                setRefrigerationType({ data: [], isFetching: false });
                console.error(exception.message)
            }
        };

        const fetchRefrigerationUnitType = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_CH_REFRIGERATION_UNIT_TYPE}`, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${UserService.getToken()}`
                    }
                });
                setRefrigerationUnitType({ data: response.data.data, isFetching: false });
            } catch (exception) {
                setRefrigerationUnitType({ data: [], isFetching: false });
                console.error(exception.message)
            }
        };


        const fetchVehiclesCombustible = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_CH_VEHICLES_COMBUSTIBLE}`, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${UserService.getToken()}`
                    }
                });
                setVehiclesCombustible({ data: response.data.data, isFetching: false });
            } catch (exception) {
                setVehiclesCombustible({ data: [], isFetching: false });
                console.error(exception.message)
            }
        };

        fetchOrganizations();
        fetchRefrigerationType();
        fetchRefrigerationUnitType();
        fetchVehiclesCombustible()
    }, []);


    useEffect(() => {
        if (!formData || !formData.vehiclesMachineryId || !formData.combustibleId || !vehiclesCombustible?.data || !isFetching) {
            return;
        }

        const parentName = vehiclesCombustible.data?.find(parent =>
            parent.listGroupEmissions.some(emission => emission.mainQuestion === formData.vehiclesMachineryId)
        )?.name;
        setSelectedVehicleCombustible(prevSelected => ({
            ...prevSelected,
            transportType: parentName,
            vehicleType: formData.vehiclesMachineryId,
            combustible: formData.combustibleId
        }));
    }, [formData, vehiclesCombustible, isFetching]);

    const handleInputChange = (e) => {
        let name, value, type, checked;

        if (e && e.target) { // Verifica si es un evento HTML estándar
            ({ name, value, type, checked } = e.target);

            if (name.toLowerCase().includes("consumption")) {
                if (value != '') {
                    setRequiredConsumption(true);
                } else {
                    setRequiredConsumption(false);
                }
            }

            const parsedValue = type === 'number' && value === "" ? "" : type === 'number' ? Number(value) : value;
            setFormData((prevFormData) => {
                const updatedFormData = {
                    ...prevFormData,
                    [name]: type === 'checkbox' ? checked : parsedValue
                };
                if (name === "isRefrigerated" && !checked) {
                    updatedFormData.refrigerationType = undefined;
                    updatedFormData.refrigerationUnitType = undefined;
                    updatedFormData.additionalConsumption = '';
                }

                return updatedFormData;
            });
        } else if (e && e.name && e.value) { // Verifica si es el evento de react-select
            name = e.name;
            value = e.value;
            setFormData((prevFormData) => {
                return {
                    ...prevFormData,
                    [name]: value,
                };
            });
        }
    };
    //TODO: ver como se ve con admin


    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            border: state.isFocused ? '1px solid var(--airco2-green-color)' : provided.border,
            borderRadius: '0px',
            borderColor: '#64748b',
            padding: '2px 5px 2px 5px',
            boxShadow: state.isFocused ? '0 0 0 1px var(--airco2-green-color)' : provided.boxShadow,
            '&:hover': {
                borderColor: state.isFocused ? 'var(--airco2-green-color)' : '#64748b',
            },
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? 'var(--airco2-green-color)' : (state.isSelected ? 'var(--airco2-orange-color)' : provided.backgroundColor),
            '&:hover': {
                backgroundColor: 'var(--airco2-green-color)',
                color: 'white'
            },
            color: state.isSelected ? 'white' : provided.color,
        }),
    };
    const handleSubmitCreateForm = async (e) => {
        e.preventDefault();
        setShowSpinner(true);
        let vehicleIdentifier = formData.identifier;
        toast.promise(
            axios.post(`${process.env.REACT_APP_CH_VEHICLE_FLEET}`,
                formData, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${UserService.getToken()}`
                }
            }).then((response) => {
                if (response.data.success) {
                    if (formData.newVehicle) {
                        setFormData(initialFormData);
                        setSelectedVehicleCombustible(initialSelectedVehicleCombustible);
                    } else {
                        if (openInModal) {
                            handleOpenEditNewWindow("list", "");
                            setNewCreatedModalElement({ type: "vehicle", value: response.data.data });
                        } else {
                            navigate(`/footprint/fleet`)
                        }
                    }
                    return response.data;
                } else {
                    throw new Error(response.data.message || 'Error inesperado');
                }
            }),
            {
                loading: t("footPrint.vehicleFleet.creating").replace("{vehicle}", vehicleIdentifier),
                success: <b>{t("footPrint.vehicleFleet.created").replace("{vehicle}", vehicleIdentifier)}.</b>,
                error: <b>{t("footPrint.vehicleFleet.error")}</b>,
            }
        );
        setShowSpinner(false);
    }

    const handleSubmitUpdateForm = async (e) => {
        e.preventDefault();
        setShowSpinner(true);
        let vehicleIdentifier = formData.identifier;

        toast.promise(
            axios.put(`${process.env.REACT_APP_CH_VEHICLE_FLEET}`, formData, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${UserService.getToken()}`,
                }
            }).then((response) => {
                if (response.data.success) {
                    if (openInModal) {
                        handleOpenEditNewWindow("list", "");
                        setNewCreatedModalElement({ type: "vehicle", value: response.data.data });
                    }
                    return response.data;
                } else {
                    throw new Error(response.data.message || 'Error inesperado');
                }
            }),
            {
                loading: t("footPrint.vehicleFleet.updating").replace("{vehicle}", vehicleIdentifier),
                success: <b>{t("footPrint.vehicleFleet.updated").replace("{vehicle}", vehicleIdentifier)}</b>,
                error: <b>{t("footPrint.vehicleFleet.error")}</b>,
            }
        ).finally(
            () => setShowSpinner(false)
        );
    }

    const renderTooltipInfoCombustible = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {t("footPrint.vehicleFleet.infoCombustible")}
        </Tooltip>
    );

    return (<div>
        <Toaster
            position="bottom-right"
            reverseOrder={false}
            toastOptions={{
                duration: 8000
            }}
        />
        {isFetching &&
            <div className='text-center flex flex-col items-center'>
                <Spinner animation="border" variant="dark"></Spinner>
                {t("footPrint.vehicleFleet.charging")}
            </div>
        }
        {!isFetching &&
            <form onSubmit={idVehicleFleet === undefined ? handleSubmitCreateForm : handleSubmitUpdateForm}>
                <div className='row'>
                    <div className='pb-2 col-12 col-md-4 col-xxl-3'>
                        <label className="block text-sm font-medium mb-1" htmlFor="identifier">
                            {t("footPrint.vehicleFleet.identifier")} <span className="text-red-500">*</span>
                        </label>
                        <input
                            id='idenfifier'
                            name='identifier'
                            className="form-userId-data bg-white  w-full"
                            type="text"
                            onChange={(e) => handleInputChange(e)}
                            value={formData?.identifier}
                            required
                        />
                    </div>

                    <div className='pb-2 col-12 col-md-4 col-xxl-3'>
                        <label className="block text-sm font-medium mb-1" htmlFor="license">
                            {t("footPrint.vehicleFleet.license")}
                        </label>
                        <input
                            name='license'
                            className="form-userId-data bg-white  w-full"
                            type="text"
                            onChange={(e) => handleInputChange(e)}
                            value={formData?.license}
                        />
                    </div>

                    <div className='pb-2 col-12 col-md-4 col-xxl-3'>
                        <label className="block text-sm font-medium mb-1" htmlFor="organizationId">
                            {t("footPrint.organization")} <span className="text-red-500">*</span>
                        </label>
                        <Select
                            id="organizationId"
                            name="organizationId"
                            className="w-full bg-white"
                            styles={customStyles}
                            options={organizations?.data?.data?.map(option => ({
                                label: option.name,
                                value: option.id
                            })).sort((a, b) => a.label.localeCompare(b.label))}
                            placeholder={`${t('footPrint.selecciona')}...`}
                            onChange={(selectedOption) => handleInputChange({
                                name: "organizationId",
                                value: selectedOption ? selectedOption.value : null
                            })}
                            value={
                                (() => {
                                    const foundItem = organizations?.data?.data?.find(item => item.id === formData.organizationId);
                                    return foundItem
                                        ? { label: foundItem.name, value: foundItem.id }
                                        : null;
                                })()
                            }
                            isLoading={organizations?.isFetching}
                            required
                        />
                    </div>

                    <div className='pb-2 col-12 col-md-4 col-xxl-3'>
                        <div className='flex flex-inline h-full items-center'>
                            <input
                                name="isOwned"
                                onChange={(e) => handleInputChange(e)}
                                checked={formData.isOwned}
                                className='custom-width-md text-xs mr-2'
                                style={{ borderColor: 'hsl(0, 0%, 80%)' }}
                                type="checkbox" />
                            <label className="block text-sm font-medium mb-0" htmlFor="isOwned">{t("footPrint.vehicleFleet.isOwned")} </label>
                        </div>
                    </div>

                    <div className='pb-2 col-12 col-md-4 col-xxl-3'>
                        <label className="block text-sm font-medium mb-1" htmlFor="vehiclesMachinery">
                            {t("footPrint.vehicleFleet.transportType")} <span className="text-red-500">*</span>
                        </label>
                        <Select
                            id="vehiclesMachinery"
                            name="vehiclesMachinery"
                            className="w-full bg-white"
                            styles={customStyles}
                            options={vehiclesCombustible?.data?.map(option => ({
                                label: option.name,
                                value: option.name
                            }))}
                            placeholder={`${t('footPrint.selecciona')}...`}
                            onChange={(selectedOption) => {
                                setSelectedVehicleCombustible(prevSelected => ({
                                    ...prevSelected,
                                    transportType: selectedOption.value,
                                    vehicleType: undefined,
                                    combustible: undefined
                                }));
                            }}
                            value={
                                (() => {
                                    if (!selectedVehicleCombustible.transportType || !vehiclesCombustible?.data) {
                                        return null;
                                    }
                                    const foundItem = vehiclesCombustible.data.find(item => item.name === selectedVehicleCombustible.transportType);
                                    return foundItem ? { label: foundItem.name, value: foundItem.name } : null
                                })()
                            }
                            isLoading={vehiclesCombustible?.isFetching}
                            required
                        />
                    </div>

                    <div className='pb-2 col-12 col-md-4 col-xxl-3'>
                        <label className="block text-sm font-medium mb-1" htmlFor="vehiclesMachinery2">
                            {t("footPrint.vehicleFleet.vehicleType")} <span className="text-red-500">*</span>
                        </label>
                        <Select
                            id="vehiclesMachinery2"
                            name="vehiclesMachinery2"
                            className="w-full bg-white"
                            styles={customStyles}
                            options={vehiclesCombustible?.data?.find(item => item.name === selectedVehicleCombustible.transportType)?.listGroupEmissions.map(option => ({
                                label: option.name,
                                value: option.mainQuestion
                            }))}
                            placeholder={`${t('footPrint.selecciona')}...`}
                            onChange={(selectedOption) => {
                                setSelectedVehicleCombustible(prevSelected => ({
                                    ...prevSelected,
                                    vehicleType: selectedOption.value,
                                    combustible: undefined
                                }));
                                handleInputChange({
                                    name: "vehiclesMachineryId",
                                    value: selectedOption ? selectedOption.value : null
                                })
                            }}
                            value={
                                (() => {
                                    if (!selectedVehicleCombustible.transportType || !selectedVehicleCombustible.vehicleType || !vehiclesCombustible?.data) {
                                        return null;
                                    }
                                    const foundItem = vehiclesCombustible.data.find(item => item.name === selectedVehicleCombustible.transportType).listGroupEmissions.find(item2 => item2.mainQuestion === selectedVehicleCombustible.vehicleType);
                                    return foundItem ? { label: foundItem.name, value: foundItem.mainQuestion } : null
                                })()
                            }
                            required
                        />
                    </div>

                    <div className='pb-2 col-12 col-md-4 col-xxl-3'>
                        <label className="block text-sm font-medium mb-1" htmlFor="combustibleId">
                            {t("footPrint.vehicleFleet.combustibleType")} <span className="text-red-500">*</span>
                        </label>
                        <Select
                            id="combustibleId"
                            name="combustibleId"
                            className="w-full bg-white"
                            styles={customStyles}
                            options={vehiclesCombustible?.data?.find(item => item.name === selectedVehicleCombustible.transportType)?.listGroupEmissions?.find(item2 => item2.mainQuestion === selectedVehicleCombustible.vehicleType)?.combustible?.map(option => ({
                                label: option.name,
                                value: option.id
                            }))}
                            placeholder={`${t('footPrint.selecciona')}...`}
                            onChange={(selectedOption) => {
                                setSelectedVehicleCombustible(prevSelected => ({
                                    ...prevSelected,
                                    combustible: selectedOption.value
                                }));
                                handleInputChange({
                                    name: "combustibleId",
                                    value: selectedOption ? selectedOption.value : null
                                })
                            }}
                            value={
                                (() => {
                                    if (!selectedVehicleCombustible.transportType || !selectedVehicleCombustible.vehicleType || !selectedVehicleCombustible.combustible || !vehiclesCombustible?.data) {
                                        return null;
                                    }
                                    const foundItem = vehiclesCombustible.data.find(item => item.name === selectedVehicleCombustible.transportType).listGroupEmissions.find(item2 => item2.mainQuestion === selectedVehicleCombustible.vehicleType).combustible.find(item3 => item3.id === selectedVehicleCombustible.combustible);
                                    return foundItem ? { label: foundItem.name, value: foundItem.name } : null
                                })()
                            } required
                        />
                    </div>

                    <div className='pb-2 col-12 col-md-4 col-xxl-3'>
                        <label className="block text-sm font-medium mb-1 flex flex-row justify-between" htmlFor="mma">
                            <span>  {t("footPrint.vehicleFleet.mma")} <span className="text-red-500">*</span></span>
                        </label>
                        <div className="flex quantity-expense-container-dark bg-white ">
                            <input
                                name="mma"
                                onChange={(e) => handleInputChange(e)}
                                value={formData.mma}
                                className='quantity-expense-input w-full'
                                type="number" step="0.01" min="0.01"
                                required
                            />
                        </div>
                    </div>

                    <div className='pb-2 col-12 col-md-4 col-xxl-3'>
                        <label className="block text-sm font-medium mb-1 flex flex-row justify-between" htmlFor="payload">
                            <span>  {t("footPrint.vehicleFleet.payload")} <span className="text-red-500">*</span></span>
                        </label>
                        <div className="flex quantity-expense-container-dark bg-white ">
                            <input
                                name="payload"
                                onChange={(e) => handleInputChange(e)}
                                value={formData.payload}
                                className='quantity-expense-input w-full'
                                type="number" step="0.01" min="0.01"
                                required
                            />
                        </div>
                    </div>


                    <div className='pb-2 col-12 col-md-4 col-xxl-3'>
                        <label className="block text-sm font-medium mb-1 flex flex-row justify-between" htmlFor="averageConsumption">
                            <span> {t("footPrint.vehicleFleet.averageConsumption")} {requiredConsumption && <span className="text-red-500">*</span>}</span>
                            <span>
                                <OverlayTrigger
                                    placement="bottom"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={renderTooltipInfoCombustible}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="var(--airco2-orange-color) " strokeLinecap="round" strokeLinejoin="round" width="24" height="24" strokeWidth="1.5">
                                        <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0"></path>
                                        <path d="M12 9h.01"></path> <path d="M11 12h1v4h1"></path>
                                    </svg>
                                </OverlayTrigger>
                            </span>
                        </label>
                        <input
                            id="averageConsumption"
                            name='averageConsumption'
                            className="form-userId-data bg-white  w-full"
                            type="number" step="0.01" min="0.01"
                            value={formData.averageConsumption}
                            onChange={(e) => handleInputChange(e)}
                            required={requiredConsumption}
                        />
                    </div>

                    <div className='pb-2 col-12 col-md-4 col-xxl-3'>
                        <label className="block text-sm font-medium mb-1 flex flex-row justify-between" htmlFor="emptyConsumption">
                            <span>  {t("footPrint.vehicleFleet.emptyConsumption")}{requiredConsumption && <span className="text-red-500">*</span>}</span>
                            <span>
                                <OverlayTrigger
                                    placement="bottom"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={renderTooltipInfoCombustible}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="var(--airco2-orange-color) " strokeLinecap="round" strokeLinejoin="round" width="24" height="24" strokeWidth="1.5">
                                        <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0"></path>
                                        <path d="M12 9h.01"></path> <path d="M11 12h1v4h1"></path>
                                    </svg>
                                </OverlayTrigger>
                            </span>
                        </label>
                        <input
                            name='emptyConsumption'
                            className="form-userId-data bg-white  w-full"
                            type="number" step="0.01" min="0.01"
                            value={formData.emptyConsumption}
                            onChange={(e) => handleInputChange(e)}
                            required={requiredConsumption}
                        />
                    </div>





                    <div className='pb-2 col-12 col-md-4 col-xxl-3'>
                        <div className='flex flex-inline h-full items-center'>
                            <input
                                name="isRefrigerated"
                                onChange={(e) => handleInputChange(e)}
                                checked={formData.isRefrigerated}
                                className='custom-width-md text-xs mr-2'
                                style={{ borderColor: 'hsl(0, 0%, 80%)' }}
                                type="checkbox" />
                            <label className="block text-sm font-medium mb-0" htmlFor="isRefrigerated">
                                {t("footPrint.vehicleFleet.isRefrigerated")}
                            </label>
                        </div>
                    </div>

                    {formData.isRefrigerated && <>
                        <div className='pb-2 col-12 col-md-4 col-xxl-3'>
                            <label className="block text-sm font-medium mb-1 flex flex-row justify-between" htmlFor="additionalConsumption">
                                <span>  {t("footPrint.vehicleFleet.additionalConsumption")}{requiredConsumption && <span className="text-red-500">*</span>}</span>
                                <span>
                                    <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltipInfoCombustible}
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="var(--airco2-orange-color) " strokeLinecap="round" strokeLinejoin="round" width="24" height="24" strokeWidth="1.5">
                                            <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0"></path>
                                            <path d="M12 9h.01"></path> <path d="M11 12h1v4h1"></path>
                                        </svg>
                                    </OverlayTrigger>
                                </span>
                            </label>
                            <input
                                name='additionalConsumption'
                                className="form-userId-data bg-white  w-full"
                                type="number" step="0.01" min="0.01"
                                value={formData.additionalConsumption}
                                onChange={(e) => handleInputChange(e)}
                                required={requiredConsumption}
                            />
                        </div>
                        <div className='pb-2 col-12 col-md-4 col-xxl-3'>
                            <label className="block text-sm font-medium mb-1 flex flex-row justify-between" htmlFor="refrigerationType">
                                <span>  {t("footPrint.vehicleFleet.refrigerationType")} <span className="text-red-500">*</span></span>
                            </label>
                            <Select
                                id="refrigerationType"
                                name="refrigerationType"
                                className="w-full bg-white"
                                styles={customStyles}
                                options={refrigerationType?.data?.map(option => ({
                                    label: option.name,
                                    value: option.id
                                }))}
                                placeholder={`${t('footPrint.selecciona')}...`}
                                onChange={(selectedOption) => handleInputChange({
                                    name: "refrigerationType",
                                    value: selectedOption ? selectedOption.value : null
                                })}
                                value={
                                    (() => {
                                        const foundItem = refrigerationType?.data?.find(item => item.id === formData.refrigerationType);
                                        return foundItem
                                            ? { label: foundItem.name, value: foundItem.id }
                                            : null;
                                    })()
                                }
                                isLoading={refrigerationType?.isFetching}
                                required={formData.isRefrigerated}
                            />
                        </div>
                        <div className='pb-2 col-12 col-md-4 col-xxl-3'>
                            <label className="block text-sm font-medium mb-1 flex flex-row justify-between" htmlFor="refrigerationUnitType">
                                <span>  {t("footPrint.vehicleFleet.refrigerationUnitType")} <span className="text-red-500">*</span></span>
                            </label>
                            <Select
                                id="refrigerationUnitType"
                                name="refrigerationUnitType"
                                className="w-full bg-white"
                                styles={customStyles}
                                options={refrigerationUnitType?.data?.map(option => ({
                                    label: option.name,
                                    value: option.id
                                }))}
                                placeholder={`${t('footPrint.selecciona')}...`}
                                onChange={(selectedOption) => handleInputChange({
                                    name: "refrigerationUnitType",
                                    value: selectedOption ? selectedOption.value : null
                                })}
                                value={
                                    (() => {
                                        const foundItem = refrigerationUnitType?.data?.find(item => item.id === formData.refrigerationUnitType);
                                        return foundItem
                                            ? { label: foundItem.name, value: foundItem.id }
                                            : null;
                                    })()
                                }
                                isLoading={refrigerationUnitType?.isFetching}
                                required={formData.isRefrigerated}
                            />
                        </div>
                    </>
                    }
                    <div className='pb-2 col-12 col-md-4 col-xxl-3'>
                        <div className='flex flex-inline h-full items-center'>
                            <input
                                id="isActive"
                                name="isActive"
                                onChange={(e) => handleInputChange(e)}
                                checked={formData.isActive}
                                className='custom-width-md text-xs mr-2'
                                style={{ borderColor: 'hsl(0, 0%, 80%)' }}
                                type="checkbox" />
                            <label className="block text-sm font-medium mb-0" htmlFor="isActive">
                                {t("footPrint.vehicleFleet.isActive")}
                            </label>
                        </div>
                    </div>

                </div>

                <div className='flex justify-end items-center'>
                    {idVehicleFleet === undefined &&
                        <div className='flex flex-inline h-full items-center'>
                            <input
                                name="newVehicle"
                                onChange={(e) => handleInputChange(e)}
                                checked={formData.newVehicle}
                                className='custom-width-md text-xs mr-2'
                                style={{ borderColor: 'hsl(0, 0%, 80%)' }}
                                type="checkbox" />
                            <label className="block text-sm font-medium mb-0" htmlFor="newVehicle">{t("footPrint.vehicleFleet.addOther")} </label>
                        </div>

                    }
                    <div>
                        <button
                            disabled={showSpinner}
                            arial-label="btn-saveFom"
                            className="btn-sm btn-green text-white ml-3"
                            type='submit'
                            style={{ width: '10rem' }} >
                            {showSpinner ?
                                <Spinner animation="border" variant='light' />
                                :
                                t("footPrint.guardar")}
                        </button>
                    </div>
                </div>

            </form>
        }
    </div >)

}