import { useTranslation } from 'react-i18next';

export default function VehicleFleetInstructions() {
    const [t, i18n] = useTranslation("global");

    return <div className="text-center px-4">
        <div className="mb-6">
            <h2 className="text-2xl text-gray-800 dark:text-gray-100 font-bold mb-4">
                {t("footPrint.vehicleFleet.instructions.title")}
            </h2>
            <p className="mb-2">{t("footPrint.vehicleFleet.instructions.line1")}</p>
            <p className="mb-2">{t("footPrint.vehicleFleet.instructions.line2")}</p>
            <p className="mb-2">{t("footPrint.vehicleFleet.instructions.line3")}</p>
            <p className="mb-2">{t("footPrint.vehicleFleet.instructions.line4")}</p>
        </div>
    </div>

}