import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { IdiomContext } from "../context/createcontext";
import banerAyudas from "../images/Banner.webp";
import enisaOsc from "../images/EnisaOscuro.webp";
import logo from "../images/logo2.webp";
import whitepaper from "../pdf/WhitepaperAirCO2.pdf";
import igape from "../images/logo-igape.webp";

function Footer() {
  const [lng, updateLng] = useContext(IdiomContext);
  const [t, i18n] = useTranslation("global");

  const [hovert, sethovert] = useState(false);

  const handleChangeT = (e) => {
    hovert === true ? sethovert(false) : sethovert(true);
  };

  const [hoverf, sethoverf] = useState(false);

  const handleChangef = (e) => {
    hoverf === true ? sethoverf(false) : sethoverf(true);
  };

  const [hoverig, sethoverig] = useState(false);

  const handleChangeig = (e) => {
    hoverig === true ? sethoverig(false) : sethoverig(true);
  };

  const [hoverli, sethoverli] = useState(false);

  const handleChangeli = (e) => {
    hoverli === true ? sethoverli(false) : sethoverli(true);
  };
  return (
    <footer>
      <div
        className="py-2 md:py-6 text-white"
        style={{ backgroundColor: "var(--airco2-green-color)" }}
      >
        <div className="max-w-6xl mx-auto px-1 sm:px-2">
          <div className="grid md:grid-cols-12 gap-8 lg:gap-20 mb-8 md:mb-12">
            <div className="md:col-span-5  lg:col-span-6 flex flex-column  justify-content pt-3 ">
              <div className="mb-3 pl-3">
                <img
                  alt="logo airCO2"
                  src={logo}
                  height="200"
                  width="130"
                ></img>
              </div>
              <div className="pl-3 pr-3"> {t("footer.f1")}</div>
            </div>

            <div className="md:col-span-6  lg:col-span-6 flex  justify-center md:justify-end pt-3 ">
              <div className="flex flex-col items-end">
                <div className="flex gap-4">
                <div className="flex" style={{bottom: '0'}}>
                <img
                    alt="logo igape"
                    className="imagen-gris self-end cursor-pointer"
                    src={igape}
                    style={{ maxWidth: "150px" }}
                    onClick={()=>window.open(`${process.env.REACT_APP_URL}/pdf/Cartel_publicidade_web_Re-acciona.pdf`, '_blank')}
                  ></img>
                </div>
                <div className="">
                  <img
                    alt="logo airCO2"
                    className="logo-enisa"
                    src={enisaOsc}
                  ></img>
                </div>
                </div>
                <div className="flex justify-center py-4" data-aos="fade-up">
                  <img
                    src={banerAyudas}
                    alt="Banner ayudas"
                    className="imagen-gris"
                    style={{ maxWidth: "500px", width:"100%" }}
                  ></img>
                </div>
              </div>
            </div>
          </div>

          <div className="md:flex md:items-center md:justify-between ">
            {/* Paginas legales, whitepaper y FAQS*/}
            <ul className="flex mb-4 md:order-1 md:ml-4 md:mb-0 pl-3 flex-wrap">
              <li className="ml-4">
                <a
                  aria-label="redirige a página de terminos"
                  className="footnew"
                  href="/terminos"
                  target="_blank"
                >
                  {t("footer.f2")}
                </a>
              </li>

              <li className="ml-4">
                <a
                  aria-label="redirige a página de cookies"
                  className="footnew"
                  href="/privacidadYcookies"
                  target="_blank"
                >
                  {t("footer.f4")}
                </a>
              </li>
              <li className="ml-4">
                <a
                  aria-label="metodologia"
                  className="footnew"
                  href={whitepaper}
                  target="_blank"
                >
                  {t("footer.whitepaper")}
                </a>
              </li>
              <li className="ml-4">
                <a href="/faqs" className="footnew" target="_blank">
                  FAQ’s
                </a>
              </li>
            </ul>

            {/* Social links */}
            <ul className="flex mb-4 md:order-1 md:ml-4 md:mb-0 pl-3">
              {/* <li className='ml-4' onMouseEnter={handleChangeT} onMouseOut={handleChangeT}>
                <a aria-label='redirige a twitter' href={'https://twitter.com/AirCO2_fintech '} target="_blank" className="link-social flex justify-center items-center rounded-full transition duration-150 ease-in-out">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-twitter" viewBox="0 0 16 16">
                    <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
                  </svg>
                </a>
              </li> */}

              {/* <li className='ml-4' onMouseEnter={handleChangef} onMouseOut={handleChangef}>
                <a aria-label='redirige a facebook' href={"https://www.facebook.com/airco2fintech"} target='_blank' className=" link-social flex justify-center items-center rounded-full transition duration-150 ease-in-out">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-facebook" viewBox="0 0 16 16">
                    <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                  </svg>

                </a>
              </li> */}
              <li
                className="ml-4"
                onMouseEnter={handleChangeig}
                onMouseOut={handleChangeig}
              >
                <a
                  aria-label="redirige a instagram"
                  href={"https://www.instagram.com/airco2_fintech/ "}
                  target="_blank"
                  className="link-social flex justify-center items-center rounded-full transition duration-150 ease-in-out"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    className="bi bi-instagram"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z" />
                  </svg>
                </a>
              </li>
              <li
                className="ml-4"
                onMouseEnter={handleChangeli}
                onMouseOut={handleChangeli}
              >
                <a
                  aria-label="redirige a linkedin"
                  href={"https://www.linkedin.com/company/airco2-fintech"}
                  target="_blank"
                  className="link-social flex justify-center items-center rounded-full transition duration-150 ease-in-out"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    className="bi bi-linkedin"
                    viewBox="0 0 16 16"
                  >
                    <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
                  </svg>
                </a>
              </li>

              <li className="ml-4">
                <a
                  href="mailto:ventas@airco2.earth"
                  className="link-social flex justify-center items-center rounded-full transition duration-150 ease-in-out"
                  aria-label="redirige a mandar un mail a ventas"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-mail"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="#ffffff"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <rect x="3" y="5" width="20" height="17" rx="2" />
                    <polyline points="3 7 12 13 21 7" />
                  </svg>
                </a>
              </li>
              {/* <li className='ml-4' onMouseEnter={handleChangeli} onMouseOut={handleChangeli}>
                <a aria-label='redirige a linkedin' href={'https://www.tiktok.com/@airco2_'} target="_blank" className="link-social flex justify-center items-center rounded-full transition duration-150 ease-in-out">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-tiktok" viewBox="0 0 16 16">
                    <path d="M9 0h1.98c.144.715.54 1.617 1.235 2.512C12.895 3.389 13.797 4 15 4v2c-1.753 0-3.07-.814-4-1.829V11a5 5 0 1 1-5-5v2a3 3 0 1 0 3 3V0Z" />
                  </svg>
                </a>
              </li> */}
            </ul>

            {/* Copyrights note */}
            <div className="text12 mb-4 pl-3">
              &copy; {new Date().getFullYear()} airCO2. All rights reserved.
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
