import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Header from '../../Header';
import Sidebar from '../../Sidebar';
import VehiclesFleetList from './VehiclesFleetList';
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import VehicleFleetInstructions from './VehicleFleetInstructions';

export default function VehiclesFleetUserPage() {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [t, i18n] = useTranslation("global");
    const startRef = useRef(null);
    const navigate = useNavigate();

    const [showInstructions, setShowInstructions] = useState(false);



    return (<div className=" sec-neutro-background flex h-screen overflow-hidden">

        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <div className="sec-neutro-background relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

            <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <Modal show={showInstructions} size={"lg"}>
                <Modal.Header className="sec-green-background">
                    <Modal.Title className="text-white">
                        {t("footPrint.instruccionesRelatedUsers.instruction")}
                    </Modal.Title>
                    <div className="flex justify-end">
                        <button onClick={() => setShowInstructions(false)}>
                            <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-tj5bde-Svg"><path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path></svg>
                        </button>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <VehicleFleetInstructions></VehicleFleetInstructions>
                </Modal.Body>
            </Modal>

            <main>
                <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto" >

                    <div className="mb-4 flex justify-between " id="start" ref={startRef}>
                        <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">{t("footPrint.vehicleFleet.listpage")}</h1>
                        <div className='flex justify-end gap-2'>

                            <button onClick={() => setShowInstructions(true)} className='flex items-center btn-sm btn-banner-o mr-4 px-4 py-2' >
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-info-square-rounded mr-2" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" stroke="white" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <path d="M12 9h.01" />
                                    <path d="M11 12h1v4h1" />
                                    <path d="M12 3c7.2 0 9 1.8 9 9s-1.8 9 -9 9s-9 -1.8 -9 -9s1.8 -9 9 -9z" />
                                </svg>
                                {t("footPrint.instruccionesRelatedUsers.instruction")}
                            </button>
                            <button className='btn-sm btn-green px-4 py-2' onClick={() => navigate('/footprint/vehicle-fleet')} >
                                {t("footPrint.vehicleFleet.new")}
                            </button>
                        </div>
                    </div>

                    <div>
                        <VehiclesFleetList></VehiclesFleetList>
                    </div>
                </div>

            </main>
        </div>
    </div>
    )

}