import React from 'react'
import { useTranslation } from 'react-i18next';
import { IdiomContext } from "../../../context/createcontext";
import { useContext,useRef } from 'react'
import calendario from "../../../images/calendario_azul.svg";
import dossier from "../../../images/Dossier_azul.svg";
import { HashLink } from 'react-router-hash-link';

export default function BloqueBanner() {
  const [lng, updateLng] = useContext(IdiomContext)
  const [t, i18n] = useTranslation("global");

  const url = `${process.env.REACT_APP_URL}/agendar-asesoria-inicial`

  return (
    <div className=' mt-5 p-4 container'>
      <div className='  flex-wrap justify-center'>
        <h1 className="flex flex-wrap justify-center text-airco2-title-concellos mt-2" style={{fontSize:'2.2rem'}} 
        
        data-aos="fade-up" data-aos-delay="100"  
        >

          <span className=' text-center px-2'>{(t("landingConcellos.empresasLimpias"))}</span>
          <span className=' text-center px-2'>{(t("landingConcellos.montes"))}  <span className='font-bold' >{(t("landingConcellos.limpios"))}</span></span>
         
        </h1>

        <div className=' flex flex-wrap'>
          <div className='text-white ' style={{fontFamily:'openReg'}}>
          <p className='pt-16 text-center text-xl text-airco2-subtitle-concellos title-ecoCircular'>{(t("landingConcellos.economiaCircular"))}</p>
         
          <div className='flex flex-wrap justify-center mt-8 gap-btn-banner  p-4  items-center'>
            <div className='flex flex-col items-center self-center'>
            <img width={84} src={dossier} alt="dossier" />
            <HashLink aria-label='inicio' className="nav-link" to={'#dossierRef'} smooth={true} offset={50} duration={500}>

            <button className=' btn btn-banner-o'>
              {(t("landingConcellos.solicitaDossier"))} 
              </button>
            </HashLink>
             
           
            </div>
            <div className='flex flex-col items-center self-center'>
            <img width={84} src={calendario} alt="calendario" />
            <a aria-label='redirige a calendario ' target='_blank' href={url}>
            <button className=' btn btn-banner'>
              {(t("landingConcellos.agendaReunion"))} 
              </button>
              </a>
            </div>

          </div>
          </div>

        </div>
      </div>




    </div>
  )
}
