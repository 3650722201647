import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Header from '../../Header';
import Sidebar from '../../Sidebar';
import VehicleFleetForm from './VehicleFleetForm';


export default function VehicleFleetPage() {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [t, i18n] = useTranslation("global");
    const startRef = useRef(null);
    const pathParams = useParams();


    return (<div className=" sec-neutro-background flex h-screen overflow-hidden">

        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <div className="sec-neutro-background relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

            <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div>
                <button arial-label="btn-saveFom" className="ml-4 mt-2 text-base font-semibold flex flex-row items-center" type='onclick' onClick={() => window.history.back()}  >
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-back" width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#334155" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M9 11l-4 4l4 4m-4 -4h11a4 4 0 0 0 0 -8h-1" />
                    </svg>
                    <span>{t("footPrint.volver")}</span>
                </button>
            </div>
            <main>
                <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto" >

                    <div className="mb-4 " id="start" ref={startRef}>
                        {pathParams.id === undefined ?
                            <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">{t("footPrint.vehicleFleet.createpage")}</h1>
                            :
                            <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">{t("footPrint.vehicleFleet.updatepage")}</h1>
                        }
                    </div>

                    <div>
                        <VehicleFleetForm></VehicleFleetForm>
                    </div>
                </div>

            </main>
        </div>
    </div>
    )

}